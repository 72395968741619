import { getProfileAPI } from "@/api/common";

const state = {
  profile: null,
  accessible_menus: null,
  role: localStorage.getItem('role') || null,
};

const mutations = {
  SET_PROFILE(state, payload) {
    state.profile = payload;
  },
  SET_ACCESSIBLE_MENU(state, payload) {
    state.accessible_menus = payload;
  },
  SET_ROLE(state, payload) {
    state.role = payload;
  }
};

const actions = {
  getProfileUser: async ({ commit }, params) => {
    commit("loading/SET_LOADING", true, { root: true });
    try {
      const result = await getProfileAPI(params);
      if (result && result.data) {
        const { data } = result;
        commit("SET_PROFILE", data.data);
        commit(
          "SET_ACCESSIBLE_MENU",
          data.data.user_group.accessible_menus,
        );
        commit("SET_ROLE", data.data.user_group.id);
        commit("loading/SET_LOADING", false, { root: true });
        return data;
      }
    } catch (error) {
      console.log(error);
      commit("loading/SET_LOADING", false, { root: true });
    }
  },
};

const getters = {
  getterAccessibleMenus: (state) => state.accessible_menus,
  getterRole: (state) => state.role,
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
