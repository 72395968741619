import HTTP_SERVICE from './http-service';

export default {
    getTouSettings(data) {
        return HTTP_SERVICE.get('/tou-settings', {params: data});
    },

    storeTouSettings(data) {
        return HTTP_SERVICE.post('/tou-settings', data);
    },
    
    deleteTouSettings(id) {
        return HTTP_SERVICE.delete(`/tou-settings/${id}`);
    },

    updateTouSettings(id, data) {
        return HTTP_SERVICE.put(`/tou-settings/${id}`, data);
    },
}