import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify'
import global from "@/plugins/global.js";
import TurnOffAutocomplete from 'vue-turn-off-autocomplete';
import handleNewTab from './utils/handleNewTab';
import VueToast from 'vue-toast-notification';
import VeeValidate from 'vee-validate';
import * as VueGoogleMaps from 'vue2-google-maps';
import BootstrapVue from "bootstrap-vue";

import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import 'vue-toast-notification/dist/theme-sugar.css';
import 'vue-toast-notification/dist/index.js';
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css';
import "@mdi/font/css/materialdesignicons.css";

Vue.use(VueToast);
Vue.use(global);
Vue.use(TurnOffAutocomplete);
Vue.use(BootstrapVue);

Vue.use(VueGoogleMaps, {
  load: {
    region: "TH",
    key: process.env.VUE_APP_KEY_GOOGLE_MAP,
    libraries: "places,drawing,visualization",
    v: 3.38
  },
  installComponents: true,
});

const config = {
  errorBagName: 'errors',
  fieldsBagName: 'fields',
  delay: 0,
  locale: 'en',
  dictionary: {
    en: {
      messages: {
        required: (field) => `${field} is required.`,
        email: 'Email address is invalid format',
      }
    }
  },
  strict: true,
  classes: false,
  classNames: {
    touched: 'touched',
    untouched: 'untouched',
    valid: 'valid',
    invalid: 'invalid',
    pristine: 'pristine',
    dirty: 'dirty'
  },
  events: 'input|blur',
  inject: true,
  validity: false,
  aria: true
};

Vue.use(VeeValidate, config);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');

handleNewTab(router, store);
