import moment from "moment";
import { ERROR_MESSAGES } from "@/constants/error-messages";

export const getErrorMessage = (code) => {
  const message = ERROR_MESSAGES[code];
  return message || code;
};

export const formatDate = (data) => {
  return data ? moment(data).format('DD-MM-YYYY') : '-';
};

export const formatDateTime = (data) => {
  return data ? moment(data).format('DD-MM-YYYY HH:mm'): '-';
};

export const tranferItem = (item) => {
  switch (item) {
    case 'user':
      return 'User Screen';
    case 'alarm':
      return 'Alarm Screen';
    case 'asset':
      return 'Asset Screen';
    case 'install':
      return 'Install Screen';
    case 'inventory':
      return 'Inventory Screen';
    case 'multi_site':
      return 'Multi Site';
    case 'single_site':
      return 'Single Site';
    case 'data_analytic':
      return 'Data Analytic';
    case 'user_group':
      return 'User Group';
    case 'app_alarm':
      return 'App Alarm';
    case 'app_value':
      return 'App Value';
    case 'app_device':
      return 'App Device';
    case 'app_energy':
      return 'App Energy';
    case 'app_multi_site':
      return 'App Multi Site';
    case 'app_single_site':
      return 'App Single Site';
    default:
      return item;
  }
}
export const getNameScreenAccess = (access_menu,device,action_type) => {
  // ,device,action_type
  const keysWithTrueView = access_menu[device]
    .filter(item => Object.values(item)[0][action_type])
    .map(item => Object.keys(item)[0]);
  return keysWithTrueView
}
export const formatLanguage = (data) => {
  switch (data) {
    case 'en':
      return 'English';
    case 'th':
      return 'Thailand';
    default:
      return data;
  }
};

export const formatDaskMode = (data) => {
  return data === 1 ? 'Yes' : 'No';
};

const getRandomCharacter = (charSet, minCount) => {
  let characters = charSet.split('');
  let chosenChar = characters[Math.floor(Math.random() * characters.length)];
  for (let i = 1; i < minCount; i++) {
    chosenChar += characters[Math.floor(Math.random() * characters.length)];
  }
  return chosenChar;
};

const randomString = (length, charSet) => {
  let result = '';
  const characters = charSet.split('');
  for (let i = 0; i < length; i++) {
    result += characters[Math.floor(Math.random() * characters.length)];
  }
  return result;
};

export const generateRandomPassword = (minLength, maxLength) => {
  const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
  const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numbers = '0123456789';
  const specialCharacters = '!@#$%^&*()_+-=[]{};\':"\\|,.<>/?~';

  const minLowercase = 1;
  const minUppercase = 1;
  const minNumber = 1;
  const minSpecial = 1;

  const minTotalLength = minLowercase + minUppercase + minNumber + minSpecial;

  if (minLength < minTotalLength || minLength > maxLength) {
    throw new Error('Invalid password length range');
  }

  let password = '';

  password += getRandomCharacter(lowercaseLetters, minLowercase);
  password += getRandomCharacter(uppercaseLetters, minUppercase);
  password += getRandomCharacter(numbers, minNumber);
  password += getRandomCharacter(specialCharacters, minSpecial);

  const remainingLength = minLength - password.length;
  const allChars =
    lowercaseLetters + uppercaseLetters + numbers + specialCharacters;
  password += randomString(remainingLength, allChars);

  password = password
    .split('')
    .sort(() => Math.random() - 0.5)
    .join('');

  password = password.substring(0, Math.min(password.length, maxLength));

  return password;
};

export const displayRealTime = () => {
  const currentTime = moment().format("ddd DD MMM YYYY | HH:mm:ss (GMTZ)");
  return currentTime;
};
