import { httpGetAuth, httpPatchAuth } from './axios';

export function getListRoleAPI(params) {
  return httpGetAuth('/user-groups', params);
}

export function getListAccessibleMenu(params) {
  return httpGetAuth(`/user-groups/${params}`);
}

export function updateAccessibleAPI(params) {
  return httpPatchAuth(`/user-groups-accessilibity/${params.id}`, params);
}
