import { formatDate, formatLanguage, formatDaskMode } from '@/utils/common';
import { deleteUserAPI, getListUserAPI , addUserAPI, editUserAPI , getListUserGroupAPI, getListSitesAPI} from '../../api/user-manager';

const state = {
  users: [],
  listSites: [],
  listUserGroup: [],
};

const mutations = {
  SET_USERS(state, payload) {
    state.users = payload;
  },
  SET_USERS_GROUP(state, payload) {
    state.listUserGroup = payload;
  },
  SET_SITES(state, payload) {
    state.listSites = payload;
  },
};

const actions = {
  getListUser: async ({ commit }, params) => {
    commit('loading/SET_LOADING', true, { root: true });
    try {
      const result = await getListUserAPI(params);
      if (result && result.data) {
        const { data } = result;
        const items = data?.data.result.map((item) => ({
          id: item.id,
          username: item.username,
          email: item.email,
          sitename: item.sites.map((el) => el.name),
          allSite: item.user_group.id === 2 ? ['All Sites'] : item.sites.map((el) => el.name),
          user_group: item.user_group.name,
          status: item.status === 'locked' ? 'Locked' : 'Unlocked',
          last_login: formatDate(item.last_login),
          login_fail: item.login_fail,
          language: formatLanguage(item.language),
          is_dark_mode: formatDaskMode(item.is_dark_mode),
          created_at: formatDate(item.created_at),
          updated_at: formatDate(item.updated_at),
        }));
        commit('SET_USERS', items);
        commit('loading/SET_LOADING', false, { root: true });
        return data;
      }
    } catch (error) {
      console.log(error);
      commit('loading/SET_LOADING', false, { root: true });
    }
  },
  getListUserGroup: async ({ commit }, params) => {
    try {
      const result = await getListUserGroupAPI(params);
      if (result && result.data) {
        const { data } = result;
        const items = data?.data.map((item) => ({
          id: item.id,
          name: item.name,
        }));
        commit('SET_USERS_GROUP', items);
        return data;
      }
    } catch (error) {
      console.log(error);
      commit('loading/SET_LOADING', false, { root: true });
    }
  },
  getListSites: async ({ commit }, params) => {
    try {
      const result = await getListSitesAPI(params);
      if (result && result.data) {
        const { data } = result;
        const items = data?.data.map((item) => ({
          id: item.id,
          name: item.name,
        }));
        commit('SET_SITES', items);
        return data;
      }
    } catch (error) {
      console.log(error);
      commit('loading/SET_LOADING', false, { root: true });
    }
  },
  deleteUser: async ({ commit }, params) => {
    commit('loading/SET_LOADING', true, { root: true });
    try {
      const result = await deleteUserAPI(params);
      if (result) {
        commit('loading/SET_LOADING', false, { root: true });
        return result
      }
    } catch (error) {
      console.log(error);
      commit('loading/SET_LOADING', false, { root: true });
    }
  },
  addUser: async ({ commit }, params) => {
    commit('loading/SET_LOADING', true, { root: true });
    try {
      const result = await addUserAPI(params);
      if (result && result.data) {
        const { data } = result;
        commit('loading/SET_LOADING', false, { root: true });
        return data;
      }
    } catch (error) {
      console.log(error);
      commit('loading/SET_LOADING', false, { root: true });
    }
  },
  editUser: async ({ commit }, params) => {
    commit('loading/SET_LOADING', true, { root: true });
    try {
      const result = await editUserAPI(params);
      if (result && result.data) {
        const { data } = result;
        commit('loading/SET_LOADING', false, { root: true });
        return data;
      }
    } catch (error) {
      console.log(error);
      commit('loading/SET_LOADING', false, { root: true });
    }

  },
};

const getters = {
  getterUserManager: (state) => state.users,
  getterUserGroup: (state) => state.listUserGroup,
  getterSites: (state) => state.listSites,
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
