import HTTP_SERVICE from './http-service';

export default {
    login(user) {
        return HTTP_SERVICE.post('/login', user).then(res => {
            if(res?.data?.data?.token) {
                HTTP_SERVICE.instance.defaults.headers.common['Authorization'] = `Bearer ${res?.data?.data?.token}`;
            }
            return res;
        });
    },
    logout() {
        return HTTP_SERVICE.post('/logout').finally(() => {
            delete HTTP_SERVICE.instance.defaults.headers.common['Authorization'];
            return {};
        });
    },
    forgotPassword(data) {
        return HTTP_SERVICE.post('/forgot-password', data);
    },
    resetPassword(data) {
        return HTTP_SERVICE.post('/reset-password', data);
    },
    getVersion() {
        return HTTP_SERVICE.get('/version');
    },
    getAuthentication() {
        return HTTP_SERVICE.get('/authentication');
    },
}