<template>
  <div class="loading">
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
export default {
  computed: {
    isLoading() {
      return this.$store.state.loading.loading;
    },
  },
};
</script>

<style lang="scss">
.loading {
  .v-overlay.v-overlay--active {
    z-index: 9999 !important;
  }
}
</style>
