import HTTP_SERVICE from './http-service';

export default {
    getMultipleSites(data) {
        return HTTP_SERVICE.get('multiple-sites', {params: data});
    },
    getListGlobals() {
        return HTTP_SERVICE.get('/get-list-global');
    },
    updateSiteConfig(id, data) {
        return HTTP_SERVICE.put(`/multiple-sites/config/${id}`, data);
    },
    storeProject(data) {
        return HTTP_SERVICE.post('/projects', data);
    },
}