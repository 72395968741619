import HTTP_SERVICE from './http-service';

export default {
    getListTariffs(data) {
        return HTTP_SERVICE.get('/tariffs', {params: data});
    },

    storeTariff(data) {
        return HTTP_SERVICE.post('/tariffs', data);
    },
    
    deleteTariff(id) {
        return HTTP_SERVICE.delete(`/tariffs/${id}`);
    },

    getTariff(id) {
        return HTTP_SERVICE.get(`/tariffs/${id}`);
    },

    updateTariff(id, data) {
        return HTTP_SERVICE.put(`/tariffs/${id}`, data);
    },
}