const state = {
  loading: false,
};

const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
};

const actions = {};

export default {
  state,
  mutations,
  actions,
  namespaced: true,
};
