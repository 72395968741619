import HTTP_SERVICE from './http-service';

export default {
    getListHolidays(data) {
        return HTTP_SERVICE.get('/holidays', {params: data});
    },

    storeHoliday(data) {
        return HTTP_SERVICE.post('/holidays', data);
    },
    
    deleteHoliday(id) {
        return HTTP_SERVICE.delete(`/holidays/${id}`);
    },

    getHoliday(id) {
        return HTTP_SERVICE.get(`/holidays/${id}`);
    },

    updateHoliday(id, data) {
        return HTTP_SERVICE.put(`/holidays/${id}`, data);
    },
}