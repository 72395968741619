import { login } from '../../api/auth';

const state = {
  user: null,
  token: null,
  isLogin: false,
};

const mutations = {
  SET_USER(state, user) {
    state.user = user;
  },

  SET_TOKEN(state, token) {
    state.token = token;
  },

  SET_IS_LOGIN(state, isLogin) {
    state.isLogin = isLogin;
  },
};

const actions = {
  login: async ({ commit }, { username, password }) => {
    commit("loading/SET_LOADING", true, { root: true });
    try {
      const result = await login(username, password);
      if (result && result.data) {
        const { data } = result;
        commit("SET_IS_LOGIN", true);
        commit("SET_USER", data.data.user);
        commit("SET_TOKEN", data.data.token);
        commit('loading/SET_LOADING', false, { root: true });
        return data;
      }
    } catch (error) {
      console.log(error);
      commit("SET_IS_LOGIN", false);
      commit('loading/SET_LOADING', false, { root: true });
    }
  },

  logout({ commit }) {
    commit('SET_USER', null);
    commit('SET_TOKEN', null);
    commit('SET_IS_LOGIN', false);
  },
};

const getters = {
}


export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
