export default (router, store) => {
  const STORAGE_DETECTOR = "STORAGE_DETECTOR";
  const STORAGE_COUNTER = "STORAGE_COUNTER";

  let disabled = false;

  localStorage.setItem(STORAGE_DETECTOR, Math.random().toString());

  addEventListener("storage", (event) => {
    if(!localStorage.getItem("token")) return

    if (event.key === STORAGE_DETECTOR) {
      if(!sessionStorage.getItem("token")) return
      localStorage.setItem(STORAGE_COUNTER, Math.random().toString());
    }

    if (disabled) return;

    if (
      event.key === STORAGE_COUNTER &&
      !sessionStorage.getItem("token")
    ) {
      sessionStorage.setItem("token", localStorage.getItem("token"));
      sessionStorage.setItem("user", localStorage.getItem("user"));

      const token = sessionStorage.getItem("token");
      const user = JSON.parse(sessionStorage.getItem("user"));

      router.replace( router.history._startLocation );
      store.dispatch("login", { token, user });
      disabled = true;
    }
  });
};
