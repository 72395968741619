import HTTP_SERVICE from './http-service';
import axios from 'axios';

export default {
    getSingleSite(id) {
        return HTTP_SERVICE.get(`/single-site/${id}`);
    },
    getDataWeather(lat, long) {
        return axios.get(`https://api.openweathermap.org/data/3.0/onecall?lat=${lat}&lon=${long}&exclude=minutely,hourly,alerts&appid=37fda0833aab7db6bedac01d28284c59`);
    },
}