import { formatDate, getNameScreenAccess } from '@/utils/common';
import {
  getListRoleAPI,
  getListAccessibleMenu,
  updateAccessibleAPI,
} from '../../api/role-manage';

const state = {
  listRole: [],
  accessible_menu: [],
};

const mutations = {
  SET_ROLE_MANAGE(state, payload) {
    state.listRole = payload;
  },
  SET_ACCESSIBLE_MENU(state, payload) {
    state.accessible_menu = payload;
  },
};

const actions = {
  getListRole: async ({ commit }, params) => {
    commit('loading/SET_LOADING', true, { root: true });
    try {
      const result = await getListRoleAPI(params);
      if (result && result.data) {
        const { data } = result;
        const items = data?.data.map((item) => ({
          id: item.id,
          user_group: item.name,
          users: item.users_count,
          lastUpdate: formatDate(item.updated_at),
          web_view: getNameScreenAccess(item.accessible_menus,'web','view'),
          web_control: getNameScreenAccess(item.accessible_menus,'web','action'),
          app_view: getNameScreenAccess(item.accessible_menus,'app','view'),
          app_control: getNameScreenAccess(item.accessible_menus,'app','action'),
          // screen_access: getNameScreenAccess(item.accessible_menus,'app','view'),
        }));
        commit('SET_ROLE_MANAGE', items);
        commit('loading/SET_LOADING', false, { root: true });
        return data;
      }
    } catch (error) {
      console.log(error);
      commit('loading/SET_LOADING', false, { root: true });
    }
  },
  getListAccessible: async ({ commit }, params) => {
    commit('loading/SET_LOADING', true, { root: true });
    try {
      const result = await getListAccessibleMenu(params);
      if (result && result.data) {
        const { data } = result;
        const item_web = data?.data?.accessible_menus?.web?.map((item) => {
          const key = Object.keys(item)[0];
          return {
            name: key,
            ...item[key],
          };
        });
        const item_app = data?.data?.accessible_menus?.app?.map((item) => {
          const key = Object.keys(item)[0];
          return {
            name: key,
            ...item[key],
          };
        });
        commit('SET_ACCESSIBLE_MENU', { item_web, item_app });
        commit('loading/SET_LOADING', false, { root: true });
        return data;
      }
    } catch (error) {
      console.log(error);
      commit('loading/SET_LOADING', false, { root: true });
    }
  },
  updateAccessible: async ({ commit }, params) => {
    commit('loading/SET_LOADING', true, { root: true });
    try {
      const result = await updateAccessibleAPI(params);
      if (result && result.data) {
        const { data } = result;
        commit('loading/SET_LOADING', false, { root: true });
        return data;
      }
    } catch (error) {
      console.log(error);
      commit('loading/SET_LOADING', false, { root: true });
    }
  },
};

const getters = {
  getterRoleManage: (state) => state.listRole,
  getterAccessible: (state) => state.accessible_menu,
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
