import HTTP_SERVICE from './http-service';

export default {
    getListAssets(data) {
        return HTTP_SERVICE.get('/assets', {params: data});
    },
    getAsset(id) {
        return HTTP_SERVICE.get(`/assets/${id}`);
    },
    storeAsset(data) {
        return HTTP_SERVICE.post('/assets', data);
    },
    updateAsset(id, data) {
        return HTTP_SERVICE.put(`/assets/${id}`, data);
    },
    deleteAsset(id) {
        return HTTP_SERVICE.delete(`/assets/${id}`);
    },
    retrieveInfo(sn) {
        return HTTP_SERVICE.get(`/assets-retrieve-info/${sn}`);
    },
    storeMaintenanceSchedule(data) {
        return HTTP_SERVICE.post('/create-maintenance', data);
    },
    getAssetDetail(id) {
        return HTTP_SERVICE.get(`/asset-detail/${id}`);
    },
    exportHistoryRecord(data) {
        return HTTP_SERVICE.get('/export-history-record', {params: data});
    },
}