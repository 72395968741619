import { httpDeleteAuth, httpGetAuth, httpPatchAuth, httpPostAuth } from './axios';

export function getListUserAPI(params) {
  return httpGetAuth('/users', params);
}

export function deleteUserAPI(params) {
  return httpDeleteAuth(`/users/${params}`);
}

export function addUserAPI(params) {
  return httpPostAuth(`/users`,params);
}

export function editUserAPI(params) {
  return httpPatchAuth(`/users/${params.id}`,params);
}

export function getListUserGroupAPI(params) {
  return httpGetAuth('/user-groups', params)
}

export function getListSitesAPI(params) {
  return httpGetAuth('/sites', params)
}
