export const ERROR_MESSAGES = {
  error_084: 'Username or password is incorrect. Please try again.',
  error_087: 'Login successful.',
  error_088: 'Logout successful.',
  error_097: 'Token is expired.',
  error_098: 'User not found.',
  error_106: 'Unauthorized.',
  error_1000: 'User group not found.',
  error_1001: 'Delete user successful.',
  error_1002: 'FCM token not found.',
  error_1003: 'Invalid authentication.',
  error_1004: 'Success.',
  error_1005: 'Get list user successful.',
  error_1006: 'Get list user group successful.',
  error_1007: 'Get list site successful.',
  error_1008: 'Get user info successful.',
  error_1009: 'Get user group info successful.',
  error_1010: 'Update user group successful.',
  error_1011: 'Update user successful.',
  error_1012: 'Add new user successful.',
  error_1013: 'Not found.',
  error_1014: 'Invalid parameters.',
  error_1015: 'Method not allowed.',
  error_1016: 'Action not allowed.',
  error_1018: 'Internal server error.',
  error_1019: 'The keys in the accessible menus must remain unchanged.',
  error_1020: 'Send notify failed.',
  error_1021: 'Send Notify successful.',
  error_1024: 'Username is required.',
  error_1025: 'Email is required.',
  error_1026: 'User group is required.',
  error_1027: 'Password is required.',
  error_1028: 'Site is required.',
  error_1029: 'The username field must be 3 to 20 characters.',
  error_1030:
    'The username field must include numbers, lowercase letters, and not contain special characters.',
  error_1031:
    'The username already exists. Please choose a different username.',
  error_1032: 'The status already exists. Please choose a different status.',
  error_1033: 'The email already exists. Please choose a different email.',
  error_1034: 'The email is invalid.',
  error_1035: 'The password field must be 8 to 32 characters.',
  error_1036:
    'The password field must include numbers, letters and special characters.',
  error_1037: 'The user group does not exist.',
  error_1038: 'The site does not exist.',
  error_1039: 'The devices do not exist.',
  error_1040: 'Add fcm token successful.',
  error_1041: 'Remove fcm token successful.',
  error_1042: 'The alarm_enable field only accepts boolean values.',
  error_1043: 'The event_enable field only accepts boolean values.',
  error_1044: 'The summary_enable field only accepts boolean values.',
  error_1045: 'The user notification does not exist.',
  error_1046: 'The user group permission denied.',
  error_1047: 'The field sort does not exist.',
  error_1048: 'The type sort does not exist.',
  error_1049: 'The password field format is invalid.',
  error_1053: 'Get user profile successful.',
  error_1054: 'The site id field must be an array.',
};
